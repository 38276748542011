import { checkServicesPagePermissions } from "@/router/guards/my-org-company.guards";
import { RouteRecordRaw } from "vue-router";

export default {
  path: "org-company/:idElement",
  name: "MyOrgCompany",
  component: () => import(/* webpackChunkName: "my-org-company-view" */ "@/views/private/MyOrgCompany.vue"),
  props: true,
  children: [
    {
      path: "anagrafica",
      name: "MyOrgCompanyAnagrafica",
      component: () => import(/* webpackChunkName: "my-org-company-anagrafica" */ "@/components/my-org-company/Anagrafica.vue"),
    },
    {
      path: "sincronizzazione",
      name: "MyOrgCompanySincronizzazione",
      component: () => import(/* webpackChunkName: "my-org-company-sincronizzazione" */ "@/components/my-org-company/sincronizzazione/Sincronizzazione.vue"),
    },
    {
      path: "moduli",
      name: "MyOrgCompanyModuli",
      component: () => import(/* webpackChunkName: "my-org-company-moduli" */ "@/components/my-org-company/Moduli.vue"),
    },
    {
      path: "preferenze-corrispondenza",
      name: "MyOrgCompanyPreferenzeCorrispondenza",
      component: () => import(/* webpackChunkName: "my-org-company-preferenze-corrispondenza" */ "@/components/my-org-company/PreferenzeCorrispondenza.vue"),
    },
    {
      path: "tipologie-servizi",
      name: "MyOrgCompanyTipologieServizi",
      component: () => import(/* webpackChunkName: "my-org-company-tipologie-servizi" */ "@/components/my-org-company/TipologieServizi.vue"),
      beforeEnter: [checkServicesPagePermissions],
    },
    {
      path: "associazione-fornitori",
      name: "MyOrgCompanyAssociazioneFornitori",
      component: () => import(/* webpackChunkName: "my-org-company-tipologie-servizi" */ "@/components/my-org-company/AssociazioneFornitori.vue"),
    },
    {
      path: "funzionalita-aggiuntive",
      name: "MyOrgCompanyFunzionalitaAggiuntive",
      component: () => import(/* webpackChunkName: "my-org-company-funzionalita-aggiuntive" */ "@/components/my-org-company/FunzionalitaAggiuntive.vue"),
    },
    {
      path: "qualifiche-fornitori",
      name: "MyOrgCompanySupplierQualifications",
      component: () => import(/* webpackChunkName: "my-org-company-qualifiche-fornitori" */ "@/components/my-org-company/SupplierQualifications.vue"),
    },
    {
      path: "gestione-ras",
      name: "MyOrgCompanyRasPurchase",
      component: () => import(/* webpackChunkName: "my-org-company-qualifiche-fornitori" */ "@/components/my-org-company/RasPurchase.vue"),
    },
    {
      path: "gestione-documentale-fornitori",
      name: "MyOrgCompanyDocumentalePurchase",
      component: () => import(/* webpackChunkName: "my-org-company-qualifiche-fornitori" */ "@/components/my-org-company/DocumentalePurchase.vue"),
    },
    {
      path: "preferenze-email",
      name: "MyOrgEmailPreferences",
      component: () => import(/* webpackChunkName: "my-org-company-qualifiche-fornitori" */ "@/components/my-org-company/CustomEmail.vue"),
    },
  ],
} as RouteRecordRaw;
